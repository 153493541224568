import React from 'react';
import { Container } from 'react-bootstrap';
import logo from '../assets/STACKFY_LOGO_WHITE_2.png';
import lang_icon from '../assets/lang-icon.svg';
import facebook from '../assets/facebook.svg';
import x from '../assets/x.svg';
import tiktok from '../assets/tiktok.svg';
import telegram from '../assets/telegram.svg';
import youtube from '../assets/youtube.svg';
import instagram from '../assets/instagram.svg';
import appStore from '../assets/appstore.svg';
import googlePlay from '../assets/googleplay.svg';

const Footer = () => {
    return (
        <footer className='py-5'>
            <Container>
                <div className='d-flex align-items-center justify-content-between pb-3 flex-wrap gap-3'>
                    <img className='footer-logo' src={logo} height={56} />
                    <div className='position-relative'>
                        <img className='lang-icon' src={lang_icon} />
                        <select
                            className="form-select"
                            id="languageSelect"
                            aria-label="Language select"
                        >
                            <option value="pt">Português</option>
                            <option value="en">English</option>
                            <option value="es">Español</option>
                            <option value="fr">Français</option>
                            <option value="de">Deutsch</option>
                            <option value="it">Italiano</option>
                            <option value="zh">中文 (Chinese)</option>
                            <option value="ar">العربية (Arabic)</option>
                            <option value="hi">हिंदी (Hindi)</option>
                        </select>
                    </div>
                </div>
                <p className='py-3 page-desc fw-medium'>Bitcoin com facilidade e privacidade.</p>
                <div className='py-5 mb-5 row'>
                    <div className='col-xl-8'>
                        <div className='row'>
                            <div className='col-lg-4 col-md-6'>
                                <div className='d-flex flex-column gap-1 mb-5'>
                                    <h5 className='f-title mb-0'>Sobre nós</h5>
                                    <div className='f-flex d-flex flex-column'>
                                        <a href='#' className='f-link'>Quem somos</a>
                                        <a href='#' className='f-link'>Imprensa</a>
                                        <a href='#' className='f-link'>Carreira</a>
                                    </div>
                                </div>
                                <div className='d-flex flex-column gap-1 mb-5'>
                                    <h5 className='f-title mb-0'>Transparência</h5>
                                    <div className='f-flex d-flex flex-column'>
                                        <a href='#' className='f-link'>Taxas e limites</a>
                                        <a href='#' className='f-link'>Termos de uso</a>
                                        <a href='#' className='f-link'>Compliance</a>
                                        <a href='#' className='f-link'>Política de privacidade</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='d-flex flex-column gap-1 mb-5'>
                                    <h5 className='f-title mb-0'>Educação</h5>
                                    <div className='f-flex d-flex flex-column'>
                                        <a href='#' className='f-link'>Blog</a>
                                        <a href='#' className='f-link'>O que é Bitcoin?</a>
                                        <a href='#' className='f-link'>Redes do Bitcoin?</a>
                                        <a href='#' className='f-link'>O que é Blockchain?</a>
                                        <a href='#' className='f-link'>Criptonário</a>
                                    </div>
                                </div>
                            </div>
                            <div className='col-lg-4 col-md-6'>
                                <div className='d-flex flex-column gap-1 mb-5'>
                                    <h5 className='f-title mb-0'>Tecnologia</h5>
                                    <div className='f-flex d-flex flex-column'>
                                        <a href='#' className='f-link'>API</a>
                                        <a href='#' className='f-link'>Segurança</a>
                                        <a href='#' className='f-link'>Status</a>
                                    </div>
                                </div>
                                <div className='d-flex flex-column gap-1 mb-5'>
                                    <h5 className='f-title mb-0'>Ajuda</h5>
                                    <div className='f-flex d-flex flex-column'>
                                        <a href='#' className='f-link'>Começar a investir</a>
                                        <a href='#' className='f-link'>Depósitos em criptos</a>
                                        <a href='#' className='f-link'>Depósitos em reais</a>
                                        <a href='#' className='f-link'>Saques em reais</a>
                                        <a href='#' className='f-link'>Saques em cripto</a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xl-4 d-flex flex-column align-items-xl-end align-items-start gap-5'>
                        <div className='f-right'>
                        <div className='d-flex f-flex flex-column gap-2 mb-4 pb-2'>
                                <h5 className='f-title mb-0'>Sobre nós</h5>
                                <div className='f-socials'>
                                    <a className='f-social-link' href='#'>
                                        <img src={instagram} />
                                    </a>
                                    <a className='f-social-link' href='#'>
                                        <img src={x} />
                                    </a>
                                    <a className='f-social-link' href='#'>
                                        <img src={facebook} />
                                    </a>
                                    <a className='f-social-link' href='#'>
                                        <img src={telegram} />
                                    </a>
                                    <a className='f-social-link' href='#'>
                                        <img src={youtube} />
                                    </a>
                                    <a className='f-social-link' href='#'>
                                        <img src={tiktok} />
                                    </a>
                                </div>
                            </div><div className='d-flex f-flex flex-column gap-2 mb-4 pb-2'>
                                <h5 className='f-title mb-0'>Sobre nós</h5>
                                <div className='f-downloads'>
                                    <a className='fd-link' href='#'>
                                        <img src={googlePlay} className='fd-img' />
                                    </a>
                                    <a className='fd-link' href='#'>
                                        <img src={appStore} className='fd-img' />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='d-flex flex-column gap-1 align-items-center'>
                    <p className='page-desc fw-light mb-0'>Stackfy Ltda. - CNPJ 00.000.000/0002-00 - Av. dos Autonomistas, 2561 - Vila Yara, Osasco - SP, 06090-020, Brasil</p>
                    <p className='footer-small fw-light mb-0'>As transações com criptoativos são consideradas investimentos de alto risco e não são recomendadas para todos os tipos de investidores.</p>
                </div>
            </Container>
        </footer>
    );
};

export default Footer;
