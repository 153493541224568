import React from 'react';
import {Container, Button } from 'react-bootstrap';
import hero_img from '../assets/hero-img.png';

const Hero = () => {
    return (
        <section className="py-5 my-lg-5 my-3 hero-sec">
            <Container>
                <div className="row">
                    {/* Left Section - Content */}
                    <div className="col-lg-6">
                        <div className="hero-content align-items-lg-start align-items-center text-lg-start text-center">
                            <div className='d-flex flex-column gap-2'>
                                <h4 className="hero-title mb-0">
                                    <span className="d-md-block d-inline">BITCOIN</span>
                                    <span className="d-md-block d-inline">DE UM JEITO</span>
                                    <span className="d-md-block d-inline">SIMPLES</span>
                                </h4>
                                <p className='hero-para fw-medium'>A plataforma mais <span className='text-primary fw-bold'>completa</span> para 
                                você comprar e vender bitcoins 
                                com <span className='text-primary fw-bold'>segurança</span> e <span className='text-primary fw-bold'>privacidade</span>.</p>
                            </div>
                            <Button variant='primary' className='nav-btn'>Criar conta</Button>
                        </div>
                    </div>

                    {/* Right Section - Image */}
                    <div className="col-lg-6 text-center pt-lg-0 pt-5">
                        <img
                            src={hero_img}
                            alt="Hero"
                            className="img-fluid"
                            style={{ maxHeight: '500px', maxWidth: '100%', width: 'auto' }}
                        />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default Hero;
