import React from 'react';
import { Container, Accordion  } from 'react-bootstrap';
import girl from '../assets/girl-bg.png';

const FaqSec = () => {
    return (
        <section className="pt-5 mb-lg-5 mb-3 faq-sec">
            <Container>
                <div className='row flex-lg-row flex-column-reverse'>
                    <div className='col-lg-6 pt-lg-0 pt-5 text-center d-flex flex-column justify-content-end'>
                        <img src={girl} className='faq-girl img-fluid' />
                    </div>
                    <div className='col-lg-6'>
                        <div className='d-flex flex-column'>
                        <p className='page-desc mb-0 fw-semibold faq-desc'>Ajuda</p>
                            <h3 className='page-title faq-title fw-bold text-white'>Dúvidas frequentes</h3>
                        </div>
                        <Accordion defaultActiveKey="0" className='mt-4 mb-4'>
                            {/* First Item */}
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Criei minha conta na Stackfy. E agora?</Accordion.Header>
                                <Accordion.Body>
                                Agora que você possui sua conta na Stackfy, é hora de começar a mexer na plataforma. Mas não se preocupe: estamos aqui para simplificar. Para começar a transacionar, você precisa, primeiramente, fazer um depósito em reais na sua conta.

                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Second Item */}
                            <Accordion.Item eventKey="1">
                                <Accordion.Header>Como faço um depósito para minha conta?</Accordion.Header>
                                <Accordion.Body>
                                    You can buy Bitcoin on a cryptocurrency exchange platform by creating an account, verifying your identity, and adding a payment method to purchase Bitcoin.
                                </Accordion.Body>
                            </Accordion.Item>

                            {/* Third Item */}
                            <Accordion.Item eventKey="2">
                                <Accordion.Header>Como comprar e vender Bitcoin?</Accordion.Header>
                                <Accordion.Body>
                                    Bitcoin uses blockchain technology, which is considered highly secure. However, it's essential to safeguard your wallet keys and use trusted platforms to minimize risks.
                                </Accordion.Body>
                            </Accordion.Item>
                            <Accordion.Item eventKey="3">
                                <Accordion.Header>Quais  são as taxas da Stackfy?</Accordion.Header>
                                <Accordion.Body>
                                    Bitcoin uses blockchain technology, which is considered highly secure. However, it's essential to safeguard your wallet keys and use trusted platforms to minimize risks.
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                        <div className='pt-2'><a className='btn btn-faq'>CENTRAL DE AJUDA</a></div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default FaqSec;
