import React from 'react';
import { Navbar, Nav, NavDropdown, Container } from 'react-bootstrap';
import logo from '../assets/STACKFY_LOGO_WHITE_2.png';

const CustomNavbar = () => {
  return (
    <Navbar bg="light" variant="light" expand="lg">
      <Container>
        {/* Logo as an Image */}
        <Navbar.Brand href="#" className='me-4'>
          <img 
            src={logo}
            alt="Logo"
            className="nav-logo"
          />{' '}
        </Navbar.Brand>

        {/* Toggle button for mobile screens */}
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />

        {/* Collapsible Navbar Links */}
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto gap-2">
            {/* First Dropdown */}
            {/*
            <NavDropdown title="Serviços" id="dropdown1">
              
              <NavDropdown.Item href="#action1">Action 1</NavDropdown.Item>
              <NavDropdown.Item href="#action2">Action 2</NavDropdown.Item>
              <NavDropdown.Item href="#action3">Action 3</NavDropdown.Item>
              
            </NavDropdown>
            */}
            {/* Second Dropdown */}
            {/*
            <NavDropdown title="Mais" id="dropdown2">
              
              <NavDropdown.Item href="#action4">Action 4</NavDropdown.Item>
              <NavDropdown.Item href="#action5">Action 5</NavDropdown.Item>
              <NavDropdown.Item href="#action6">Action 6</NavDropdown.Item>
              
            </NavDropdown>
            */}
          </Nav>

          <Nav className='align-items-center'>
            {/* Right-side Link */}
            <Nav.Link href="#right-link" className="me-md-4 me-3">Fazer Login</Nav.Link>

            {/* Right-side Button */}
            <Nav.Link className='btn btn-primary nav-btn'>Inscreva-se</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default CustomNavbar;
