import React from 'react';
import { Container, Button } from 'react-bootstrap';
import step_1 from '../assets/step-1.svg';
import step_2 from '../assets/step-2.svg';
import step_3 from '../assets/step-3.svg';
import steps_pill from '../assets/steps-pill.svg';
import steps_bg from '../assets/steps-bg.png';

const StepsSec = () => {
    return (
        <section className="py-5 my-lg-5 my-3 app-sec">
            <Container>
                <div className='d-flex flex-column align-items-center mx-auto text-center gap-3 page-info'>
                    <h3 className='page-title fw-bold steps-title'>Comece a comprar Bitcoin em apenas 3 passos</h3>
                </div>
                <div className="row py-lg-5 py-4 justify-content-center">
                    <div className='col-lg-4 col-md-6 pt-2 pb-3'>
                        <div className='step-flex'>
                            <img src={step_1} className='sf-img' />
                            <div className='d-flex flex-column gap-1'>
                                <div className='d-flex flex-column'>
                                    <p className='mb-0 fw-bold page-desc text-primary lh-1'>PASSO 01</p>
                                    <p className='mb-0 fw-medium'>Faça seu cadastro</p>
                                    <p className='mb-0 light-text sf-desc'>Em poucos minutos, crie e ative
                                        a sua conta para usar a plataforma.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 pt-2 pb-3'>
                        <div className='step-flex'>
                            <img src={step_2} className='sf-img' />
                            <div className='d-flex flex-column gap-1'>
                                <div className='d-flex flex-column'>
                                    <p className='mb-0 fw-bold page-desc text-primary lh-1'>PASSO 02</p>
                                    <p className='mb-0 fw-medium'>Faça um depósito</p>
                                    <p className='mb-0 light-text sf-desc'>Você pode adicionar fundos
                                        usando PIX ou Boleto.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 pt-2 pb-3'>
                        <div className='step-flex'>
                            <img src={step_3} className='sf-img' />
                            <div className='d-flex flex-column gap-1'>
                                <div className='d-flex flex-column'>
                                    <p className='mb-0 fw-bold page-desc text-primary lh-1'>PASSO 03</p>
                                    <p className='mb-0 fw-medium'>Compre Bitcoin</p>
                                    <p className='mb-0 light-text sf-desc'>Comece a comprar  Bitcoin a
                                        partir de R$ 1,00.</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='pt-5 mb-5 step-bottom '>
                    <div className='col-xxl-8 col-xl-9'>
                        <div className='d-flex align-items-center justify-content-lg-between justify-content-center gap-4 flex-wrap'>
                            <h3 className='page-title fw-bold steps-title st-2 text-md-start text-center'>
                                O melhor local para
                                negociar Bitcoin
                            </h3>
                            <img src={steps_pill} className='steps-pill' />
                        </div>
                        <div className='mt-5 pt-3 trade-flex'>
                            <div className='tf-card text-center'>
                                <p className='tf-pill fw-bold text-white page-desc'>
                                    + 5K clientes
                                </p>
                                <p className='mb-0 tf-text'>soberanos aprovaram
                                    nossos serviços.
                                </p>
                            </div>
                            <div className='tf-card text-center'>
                                <p className='tf-pill fw-bold text-white page-desc'>
                                    + 5K clientes
                                </p>
                                <p className='mb-0 tf-text'>soberanos aprovaram
                                    nossos serviços.
                                </p>
                            </div>
                            <div className='tf-card text-center'>
                                <p className='tf-pill fw-bold text-white page-desc'>
                                    + 5K clientes
                                </p>
                                <p className='mb-0 tf-text'>soberanos aprovaram
                                    nossos serviços.
                                </p>
                            </div>
                            <div className='tf-card text-center'>
                                <p className='tf-pill fw-bold text-white page-desc'>
                                    + 5K clientes
                                </p>
                                <p className='mb-0 tf-text'>soberanos aprovaram
                                    nossos serviços.
                                </p>
                            </div>
                            <div className='tf-card text-center'>
                                <p className='tf-pill fw-bold text-white page-desc'>
                                    + 5K clientes
                                </p>
                                <p className='mb-0 tf-text'>soberanos aprovaram
                                    nossos serviços.
                                </p>
                            </div>
                            <div className='tf-card text-center'>
                                <p className='tf-pill fw-bold text-white page-desc'>
                                    + 5K clientes
                                </p>
                                <p className='mb-0 tf-text'>soberanos aprovaram
                                    nossos serviços.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className='d-lg-none d-block text-center pt-5'>
                        <img src={steps_bg} className='img-fluid steps-bg' />
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default StepsSec;
