import React from 'react';
import { Container, Button } from 'react-bootstrap';
import gift_1 from '../assets/featured_seasonal_and_gifts_1.svg';
import gift_2 from '../assets/featured_seasonal_and_gifts_2.svg';

const MediaSec = () => {
    return (
        <section className="py-5 my-lg-5 my-3 app-sec">
            <Container>
                <div className='d-flex flex-column align-items-center mx-auto text-center gap-3 page-info'>
                    <h3 className='page-title fw-bold steps-title w-100'>Stackfy na mídia</h3>
                </div>
                <div className="row py-lg-5 py-4 justify-content-center">
                    <div className='col-xxl-3 col-lg-4 col-sm-6 pt-2 pb-3'>
                        <div className='media-box-outer'>
                            <div className='media-box p-4'>
                                <div className='d-flex flex-column gap-5 p-2 h-100 justify-content-between'>
                                    <div className='d-flex flex-column gap-4'>
                                        <h4 className='mb-1 fw-bold mb-title'>Site 01</h4>
                                        <p className='fw-medium page-desc'>soberanos aprovaram
                                            nossos serviços.
                                        </p>
                                    </div>
                                    <a href='#' className='text-decoration-none'>
                                        <span className='text-primary fw-bold page-desc'>Ler maréria</span>
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.51269 16.9509L18.1205 5.49332M17.4384 15.2125L18.282 5.35753L8.42848 4.49703" stroke="#0255F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-lg-4 col-sm-6 pt-2 pb-3'>
                        <div className='media-box-outer'>
                            <div className='media-box p-4'>
                                <div className='d-flex flex-column gap-5 p-2 h-100 justify-content-between'>
                                    <div className='d-flex flex-column gap-4'>
                                        <h4 className='mb-1 fw-bold mb-title'>Site 02</h4>
                                        <p className='fw-medium page-desc'>soberanos aprovaram
                                            nossos serviços.
                                        </p>
                                    </div>
                                    <a href='#' className='text-decoration-none'>
                                        <span className='text-primary fw-bold page-desc'>Ler maréria</span>
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.51269 16.9509L18.1205 5.49332M17.4384 15.2125L18.282 5.35753L8.42848 4.49703" stroke="#0255F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-lg-4 col-sm-6 pt-2 pb-3'>
                        <div className='media-box-outer'>
                            <div className='media-box p-4'>
                                <div className='d-flex flex-column gap-5 p-2 h-100 justify-content-between'>
                                    <div className='d-flex flex-column gap-4'>
                                        <h4 className='mb-1 fw-bold mb-title'>Site 03</h4>
                                        <p className='fw-medium page-desc'>soberanos aprovaram
                                            nossos serviços.
                                        </p>
                                    </div>
                                    <a href='#' className='text-decoration-none'>
                                        <span className='text-primary fw-bold page-desc'>Ler maréria</span>
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.51269 16.9509L18.1205 5.49332M17.4384 15.2125L18.282 5.35753L8.42848 4.49703" stroke="#0255F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-xxl-3 col-lg-4 col-sm-6 pt-2 pb-3'>
                        <div className='media-box-outer'>
                            <div className='media-box p-4'>
                                <div className='d-flex flex-column gap-5 p-2 h-100 justify-content-between'>
                                    <div className='d-flex flex-column gap-4'>
                                        <h4 className='mb-1 fw-bold mb-title'>Site 04</h4>
                                        <p className='fw-medium page-desc'>soberanos aprovaram
                                            nossos serviços.
                                        </p>
                                    </div>
                                    <a href='#' className='text-decoration-none'>
                                        <span className='text-primary fw-bold page-desc'>Ler maréria</span>
                                        <svg width="23" height="23" viewBox="0 0 23 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M4.51269 16.9509L18.1205 5.49332M17.4384 15.2125L18.282 5.35753L8.42848 4.49703" stroke="#0255F1" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                        </svg>

                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row pb-5 justify-content-center">
                    <div className='col-md-6 pt-2 pb-3'>
                        <div className='media-bottom-box-outer mbb-1'>
                            <div className='media-bottom-box p-4'>
                                <div className='d-flex flex-column gap-5 p-2 h-100 justify-content-between'>
                                    <h4 className='mb-1 fw-bold page-desc'>Convide amigos para a Stackfy e ganhe até 10% das taxas
                                        que eles negociarem.</h4>
                                    <a href='#' className='mbb-btn btn'>
                                        Quero Ganhar!
                                    </a>
                                </div>
                            </div>
                            <img src={gift_1} className='mb-gift' />
                        </div>
                    </div>
                    <div className='col-md-6 pt-2 pb-3'>
                        <div className='media-bottom-box-outer mbb-2'>
                            <div className='media-bottom-box p-4'>
                                <div className='d-flex flex-column gap-5 p-2 h-100 justify-content-between'>
                                    <h4 className='mb-1 fw-bold page-desc'>Convide amigos para a Stackfy e ganhe até 10% das taxas
                                        que eles negociarem.</h4>
                                    <a href='#' className='mbb-btn btn'>
                                        Quero Ganhar!
                                    </a>
                                </div>
                            </div>
                            <img src={gift_2} className='mb-gift' />
                        </div>
                    </div>
                </div>
                <div className='mb-0'>
                    <div className='d-flex flex-column align-items-center mx-auto text-center gap-4 page-info'>
                        <h3 className='page-title fw-bold compare-title w-100 mb-3'><span className='d-lg-block d-inline'>Junte-se a milhares de</span>
                            <span className='d-lg-block d-inline'>holders na Stackfy hoje</span>
                        </h3>
                        <div className='text-center'>
                            <a className='btn btn-primary page-desc text-white px-5'>Comprar Bitcoin</a>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default MediaSec;
