import React from 'react';
import CustomNavbar from './components/Navbar'; // Navbar component
import Hero from './components/Hero'; // Hero section component
import AppSec from './components/AppSec'; // AppSec section component
import StepsSec from './components/StepsSec.js'; // StepsSec section component
import MediaSec from './components/MediaSec.js';
import FaqSec from './components/FaqSec.js';
import Footer from './components/Footer.js';
import './index.css'; // Custom CSS

function App() {
  return (
    <>
      {/* Navigation Bar */}
      <CustomNavbar />

      <Hero />

      <AppSec />

      <StepsSec />

      <MediaSec />

      <FaqSec />

      <Footer />

    </>
  );
}

export default App;
