import React from 'react';
import { Container, Button } from 'react-bootstrap';
import app_img_1 from '../assets/app-img-1.png';
import app_img_2 from '../assets/app-img-2.png';
import app_img_3 from '../assets/app-img-3.png';
import app_img_4 from '../assets/app-img-4.png';
import app_qr from '../assets/app-qr.png';

const AppSec = () => {
    return (
        <section className="py-5 my-lg-5 my-3 app-sec">
            <Container>
                <div className='d-flex flex-column align-items-center mx-auto text-center gap-3 page-info'>
                    <h3 className='page-title fw-bold app-title'>Aqui você encontra diversas formas para
                        comprar e vender Bitcoin.</h3>
                    <p className='mb-0 page-desc fw-medium'>Você no controle dos seus sats</p>
                </div>
                <div className="row py-4 justify-content-lg-start justify-content-center">
                    <div className='col-lg-4 col-md-6 pt-2 pb-3'>
                        <div className='app-card'>
                            <img src={app_img_1} className='ac-img' />
                            <p className='ac-pill fw-medium page-desc text-white ac-pill-1'>App</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 pt-2 pb-3'>
                        <div className='app-card'>
                            <img src={app_img_2} className='ac-img' />
                            <p className='ac-pill fw-medium page-desc text-white ac-pill-2'>Plataforma</p>
                        </div>
                    </div>
                    <div className='col-lg-4 col-md-6 pt-2 pb-3'>
                        <div className='app-card'>
                            <img src={app_img_3} className='ac-img' />
                            <p className='ac-pill fw-medium page-desc text-white ac-pill-3'>Bot Telegram</p>
                        </div>
                    </div>
                </div>
                <div className='mt-5 pt-lg-4'>
                    <div className='app-box-outer'>
                        <div className='app-box'>
                            <div className='ab-img'>
                                <img src={app_img_4} />
                            </div>
                            <div className='ab-content pb-5'>
                                <div className='abc-info d-flex flex-column gap-3'>
                                    <h3 className='fw-bold page-title abc-title fw-bold text-black mb-0'>Negocie Bitcoin a qualquer hora, em qualquer lugar.</h3>
                                    <p className='mb-0 page-desc fw-medium abc-desc'>Baixe nosso  APP para começar a
                                        negociar Bitcoin agora mesmo.</p>
                                </div>
                                <div className='d-flex justify-content-lg-between justify-content-center align-items-md-end align-items-center gap-4 flex-wrap w-100'>

                                    <div className='d-flex gap-3 align-items-center'>
                                        <img src={app_qr} className='app-qr-img' />
                                        <div className='d-flex gap-2 app-qr-flex flex-column'>
                                            <p className='mb-0 fw-medium app-qr-desc page-desc'>Escanei o código e
                                                <br /> baixe o app</p>
                                            <p className='mb-0 fw-medium app-qr-devices page-desc'>iOS & Android</p>
                                        </div>
                                    </div>
                                    <div className='d-flex align-items-center stores-flex'>
                                        <div className='d-flex align-items-center flex-column gap-3 store'>
                                            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <g clip-path="url(#clip0_25_161)">
                                                    <path d="M43.5839 37.4071C42.858 39.0841 41.9988 40.6277 41.0033 42.0469C39.6463 43.9817 38.5352 45.3209 37.6789 46.0646C36.3516 47.2853 34.9294 47.9105 33.4065 47.946C32.3132 47.946 30.9947 47.6349 29.4599 47.0038C27.9201 46.3757 26.5051 46.0646 25.2112 46.0646C23.8542 46.0646 22.3988 46.3757 20.8421 47.0038C19.2831 47.6349 18.0271 47.9638 17.0668 47.9964C15.6064 48.0586 14.1508 47.4157 12.6978 46.0646C11.7704 45.2557 10.6105 43.8691 9.22087 41.9047C7.72995 39.807 6.50422 37.3745 5.54395 34.6012C4.51554 31.6058 4 28.7051 4 25.8969C4 22.6801 4.69509 19.9057 6.08734 17.5807C7.18153 15.7132 8.63718 14.2401 10.4591 13.1586C12.2809 12.0772 14.2495 11.5261 16.3694 11.4908C17.5293 11.4908 19.0505 11.8496 20.9408 12.5548C22.8258 13.2623 24.0361 13.6211 24.5667 13.6211C24.9635 13.6211 26.308 13.2016 28.5874 12.3652C30.7428 11.5895 32.562 11.2683 34.0524 11.3948C38.0908 11.7207 41.1247 13.3127 43.1425 16.1807C39.5307 18.3691 37.7441 21.4342 37.7797 25.3662C37.8123 28.429 38.9233 30.9776 41.107 33.0013C42.0966 33.9405 43.2017 34.6664 44.4313 35.182C44.1646 35.9553 43.8832 36.696 43.5839 37.4071ZM34.322 0.960762C34.322 3.36131 33.445 5.6027 31.6969 7.6773C29.5873 10.1436 27.0357 11.5687 24.2687 11.3439C24.2334 11.0559 24.213 10.7528 24.213 10.4343C24.213 8.12973 25.2162 5.66343 26.9978 3.6469C27.8872 2.6259 29.0185 1.77694 30.3903 1.09972C31.7591 0.432598 33.0539 0.0636663 34.2716 0.000488281C34.3072 0.321404 34.322 0.64234 34.322 0.960731V0.960762Z" fill="url(#paint0_linear_25_161)" />
                                                </g>
                                                <defs>
                                                    <linearGradient id="paint0_linear_25_161" x1="24.2156" y1="0.000488281" x2="24.2156" y2="48.0005" gradientUnits="userSpaceOnUse">
                                                        <stop offset="1" stop-color="#848484" />
                                                    </linearGradient>
                                                    <clipPath id="clip0_25_161">
                                                        <rect width="48" height="48" fill="white" />
                                                    </clipPath>
                                                </defs>
                                            </svg>
                                            <p className='mb-0'>App Store</p>
                                        </div>
                                        <div className='d-flex align-items-center flex-column gap-3 store'>
                                            <svg width="45" height="48" viewBox="0 0 45 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M1.16415 48C0.388011 47.6221 0 46.6772 0 45.7323C0 45.5433 0 45.1654 0 44.9764C0 30.9922 0 17.0078 0 3.21252C0 2.4566 0.194006 1.88975 0.388011 1.13383C0.582129 0.755811 0.970141 0.377905 1.35826 0C9.70151 8.12595 17.8508 16.2519 26 24.378C17.6568 31.9369 9.50751 39.8741 1.16415 48Z" fill="url(#paint0_linear_2008_18)" />
                                                <path d="M34 16.1371C31.7215 18.363 29.2531 20.5887 26.9747 23C19.3798 15.3952 11.595 7.79031 4 0.185461C4 0.185461 4 0 4.18985 0C14.0633 5.379 23.9367 10.7581 34 16.1371Z" fill="url(#paint1_linear_2008_18)" />
                                                <path d="M5 47C12.3885 39.6666 19.9618 32.3334 27.3503 25C29.3821 27.0166 31.5987 29.0334 34 31.2333C24.3949 36.55 14.7898 41.8667 5.18469 47H5Z" fill="url(#paint2_linear_2008_18)" />
                                                <path d="M35.0217 31C32.6195 28.7601 30.4022 26.52 28 24.2801C30.4022 21.8534 32.8044 19.4267 35.2065 17C35.9456 17.3733 36.6848 17.7467 37.4239 18.3066C39.2717 19.4267 41.3044 20.36 43.1522 21.48C43.7066 21.6666 44.0761 22.04 44.4456 22.6C45.1848 23.5333 45.1848 24.4667 44.4456 25.4C44.0761 25.7733 43.7066 26.1467 43.1522 26.3334C40.5653 28.0134 37.7935 29.5067 35.0217 31Z" fill="url(#paint3_linear_2008_18)" />
                                                <defs>
                                                    <linearGradient id="paint0_linear_2008_18" x1="13" y1="0" x2="13" y2="48" gradientUnits="userSpaceOnUse">
                                                        <stop offset="1" stop-color="#848484" />
                                                    </linearGradient>
                                                    <linearGradient id="paint1_linear_2008_18" x1="19" y1="0" x2="19" y2="23" gradientUnits="userSpaceOnUse">
                                                        <stop offset="1" stop-color="#848484" />
                                                    </linearGradient>
                                                    <linearGradient id="paint2_linear_2008_18" x1="19.5" y1="25" x2="19.5" y2="47" gradientUnits="userSpaceOnUse">
                                                        <stop offset="1" stop-color="#848484" />
                                                    </linearGradient>
                                                    <linearGradient id="paint3_linear_2008_18" x1="36.5" y1="17" x2="36.5" y2="31" gradientUnits="userSpaceOnUse">
                                                        <stop offset="1" stop-color="#848484" />
                                                    </linearGradient>
                                                </defs>
                                            </svg>

                                            <p className='mb-0'>Google Play</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </section>
    );
};

export default AppSec;
